import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ThumbsUpOutline: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 52 52" fill="none">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M40.349 26.66a2.746 2.746 0 0 1 2.207 2.694v.135a2.911 2.911 0 0 1-2.911 2.912h-2.387"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M39.907 32.401c.976 0 1.766.79 1.766 1.766v.883a2.65 2.65 0 0 1-2.649 2.65h-2.208"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M38.141 37.7c.976 0 1.767.79 1.767 1.766a3.532 3.532 0 0 1-3.533 3.532h-.883"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M38.142 26.661h2.208a3.09 3.09 0 0 0 3.09-3.09c0-1.708-1.383-3.533-3.09-3.533h-9.272l1.059-2.524a9.892 9.892 0 0 0 .069-6.455A3.01 3.01 0 0 0 29.349 9h-.875c-1 0-1.811.811-1.811 1.812a6.04 6.04 0 0 1-1.893 4.39l-6.108 4.935c-.53.5-.83 1.197-.83 1.926v13.429c0 1.16.992 2.077 2.132 2.406.575.166 1.171.31 1.668.646l.754.51a23 23 0 0 0 12.878 3.944h.23"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M15.181 39.465H12a3 3 0 0 1-3-3V21.272a3 3 0 0 1 3-3h2.83a3 3 0 0 1 3 3v14.22"
    />
  </Svg>
)

export default ThumbsUpOutline
