import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ClinicOutline: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 52 52" fill="none">
    <path d="M8 39h37" stroke="currentColor" strokeWidth="2" />
    <path
      d="M16.666 38.889V26.444a3.556 3.556 0 1 1 7.111 0v8"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M42 20v19" stroke="currentColor" strokeWidth="2" />
    <path d="m11.162 20.194.172 18.695" stroke="currentColor" strokeWidth="2" />
    <path
      d="M6 18 24.985 6.887a3 3 0 0 1 3.03 0L47 18"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M36.968 26.645h-7.741" stroke="currentColor" strokeWidth="2" />
    <path d="M33.096 30.516v-7.742" stroke="currentColor" strokeWidth="2" />
  </Svg>
)

export default ClinicOutline
