import React, { FC } from 'react'
import {
  SectionTitle,
  Triad,
  TriadItem,
  ItemTitle,
  ItemParagraph,
} from './styled'
import ThumbsUpOutline from 'components/icons/thumbs-up-outline'
import ClinicOutline from 'components/icons/clinic-outline'
import PersonOutlineCheckmark from 'components/icons/person-outline-checkmark'
import { IconWrap } from 'components/icon-list/styled'
import { Section } from '../styled'

const FellowSupports: FC = () => {
  return (
    <Section dark>
      <SectionTitle>
        <span translate="no">Fellow </span>supports:
      </SectionTitle>
      <Triad>
        <TriadItem>
          <IconWrap>
            <ClinicOutline />
          </IconWrap>
          <div>
            <ItemTitle>1,500+</ItemTitle>
            <ItemParagraph>
              Clinics working with<span translate="no"> Fellow</span>
            </ItemParagraph>
          </div>
        </TriadItem>
        <TriadItem>
          <IconWrap>
            <ThumbsUpOutline />
          </IconWrap>
          <div>
            <ItemTitle>93%</ItemTitle>
            <ItemParagraph>Patient satisfaction</ItemParagraph>
          </div>
        </TriadItem>
        <TriadItem>
          <IconWrap>
            <PersonOutlineCheckmark />
          </IconWrap>
          <div>
            <ItemTitle>97%</ItemTitle>
            <ItemParagraph>
              Up to 97% patient compliance<sup>†‡</sup>
            </ItemParagraph>
          </div>
        </TriadItem>
      </Triad>
    </Section>
  )
}

export default FellowSupports
