import styled from 'styled-components'
import mq from 'styles/mq'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'

export const SectionTitle = styled.h3`
  ${heading.xl};
`

export const SectionText = styled.p`
  ${paragraph.small};
  margin-top: 0;
`

export const Section = styled.section<{ dark?: boolean }>`
  position: relative;
  background: ${({ dark }) =>
    dark ? theme.color.background.gray : theme.color.background.light};
  padding: 6rem 2.4rem;

  ${mq.medium} {
    padding: 8rem 2.4rem;
  }
`
