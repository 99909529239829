import React, { FC, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import Container from 'components/container'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ClipPath from 'components/circle/clip-path'
import sliderNavSettings from 'components/carousel-navigation'
import Testimonial from 'components/testimonial'
import {
  Section,
  SectionEyebrow,
  SectionTitle,
  Counter,
  CTAButton,
  TestimonialsHero,
  MobileTestimonialsHero,
} from './styled'
import urls from 'common/urls'

const sliderSettings = {
  ...sliderNavSettings,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
}

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  vasectomyTestimonialsResult: {
    edges: ReadonlyArray<{
      node: Testimonial
    }>
  }
  allTestimonialsResult: {
    edges: ReadonlyArray<{
      node: Testimonial
    }>
  }
  doctorTestimonialsHero: FluidImage
  mobileDoctorTestimonialsHero: FluidImage
}

const query = graphql`
  query {
    vasectomyTestimonialsResult: allTestimonialsJson(
      filter: {
        author_description: { eq: "Testing the success of a vasectomy" }
      }
      sort: { fields: [score], order: DESC }
    ) {
      edges {
        node {
          score
          quote
          name
          author_description
          date
          id
          from_physician
          is_featured
        }
      }
    }
    allTestimonialsResult: allTestimonialsJson(
      sort: { fields: [score], order: DESC }
    ) {
      edges {
        node {
          score
          quote
          name
          author_description
          date
          id
          from_physician
          is_featured
        }
      }
    }
    doctorTestimonialsHero: file(
      relativePath: { eq: "testimonials-hero-clinicians.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 85, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileDoctorTestimonialsHero: file(
      relativePath: { eq: "testimonials-hero-clinicians-mobile.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

type Props = {
  dark?: boolean
  showCustomers?: boolean
  showDoctors?: boolean
  showCTA?: boolean
  showEmdash?: boolean
  showImages?: boolean
  eyebrow?: Maybe<string>
  header?: Maybe<string>
}

const TestimonialsCarousel: FC<Props> = ({
  dark,
  showCustomers,
  showDoctors,
  showCTA,
  showEmdash = true,
  showImages = true,
  eyebrow = null,
  header = null,
}) => {
  const onVasectomyKitPage =
    typeof window !== 'undefined' &&
    window.location.pathname === urls.vasectomyKit
  const [currentSlide, setCurrentSlide] = useState(0)
  const {
    vasectomyTestimonialsResult,
    allTestimonialsResult,
    doctorTestimonialsHero,
    mobileDoctorTestimonialsHero,
  } = useStaticQuery<QueryResult>(query)
  const testimonialsResult = onVasectomyKitPage
    ? vasectomyTestimonialsResult
    : allTestimonialsResult

  const testimonialsEdges = testimonialsResult.edges

  const filteredTestimonials = testimonialsEdges.filter(
    (testimonialEdge: any) => {
      // Bypass `is_featured` flag for Vasectomy Success testimonials
      if (onVasectomyKitPage) {
        // Testimonials have already been filtered by graphQL
        return true
      } else {
        if (testimonialEdge.node.is_featured) {
          if (showDoctors && testimonialEdge.node.from_physician) {
            return true
          } else if (showCustomers && !testimonialEdge.node.from_physician) {
            return true
          }
        }
      }
      return false
    }
  )

  return (
    <Section dark={dark}>
      <Container>
        {eyebrow && <SectionEyebrow>{eyebrow}</SectionEyebrow>}
        {header && <SectionTitle>{header}</SectionTitle>}
        {showImages && showDoctors && (
          <>
            <TestimonialsHero
              fluid={{
                ...doctorTestimonialsHero.childImageSharp.fluid,
              }}
              alt=""
            />
            <MobileTestimonialsHero
              fluid={{
                ...mobileDoctorTestimonialsHero.childImageSharp.fluid,
              }}
              alt=""
            />
          </>
        )}
        <Counter>
          {currentSlide + 1} of {filteredTestimonials.length}
        </Counter>
        <Slider
          {...sliderSettings}
          beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
        >
          {filteredTestimonials.map(({ node }) => (
            <Testimonial showEmdash={showEmdash} key={node.id} data={node} />
          ))}
        </Slider>
        {showCTA ? (
          <a href={urls.reviews}>
            <CTAButton>See all reviews</CTAButton>
          </a>
        ) : null}
      </Container>
    </Section>
  )
}

export default TestimonialsCarousel
