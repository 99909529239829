import React, { FC } from 'react'
import { ClipSvg } from './styled'

const path = {
  thick: (
    <path d="M.5 1A.5.5 0 0 1 .221.915a.5.5 0 0 1-.182-.22.5.5 0 0 1 .046-.474.5.5 0 0 1 .22-.182.5.5 0 0 1 .475.046.5.5 0 0 1 .181.22.5.5 0 0 1-.046.475.5.5 0 0 1-.22.181A.5.5 0 0 1 .5 1zm0-.862a.361.361 0 1 0 .141.029A.36.36 0 0 0 .5.138z" />
  ),
  thin: (
    <path d="M.5,1A.5.5,0,0,1,.22.915.5.5,0,0,1,.039.695.5.5,0,0,1,.085.22.5.5,0,0,1,.305.039.5.5,0,0,1,.779.085a.5.5,0,0,1,.181.22A.5.5,0,0,1,.915.779a.5.5,0,0,1-.22.181A.5.5,0,0,1,.5,1ZM.5.071A.427.427,0,0,0,.26.145.43.43,0,0,0,.105.333.428.428,0,0,0,.145.74.43.43,0,0,0,.333.895.428.428,0,0,0,.74.855.43.43,0,0,0,.895.667.428.428,0,0,0,.855.26.43.43,0,0,0,.667.105.426.426,0,0,0,.5.071Z" />
  ),
}

export type ThicknessType = keyof typeof path

const ClipPath: FC<{ id: string; thickness?: ThicknessType }> = ({
  id,
  thickness = 'thick',
}) => (
  <ClipSvg viewBox="0 0 1 1" className="clip">
    <clipPath id={`clip-${id}`} clipPathUnits="objectBoundingBox">
      {path[thickness]}
    </clipPath>
  </ClipSvg>
)

export default ClipPath
