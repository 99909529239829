import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Container, Quote, Author, Description, Circle } from './styled'

const Testimonial: FC<{ data: Testimonial; showEmdash: boolean }> = ({
  data,
  showEmdash,
}) => {
  const authorName = data.name
  const authorDescription = data.author_description
  return (
    <Container key={data.id}>
      <Quote>{data.quote}</Quote>
      {authorName && authorDescription ? (
        <>
          <Author>{authorName}</Author>
          <Description>{authorDescription}</Description>
        </>
      ) : (
        <Author showEmdash={showEmdash}>
          {authorName || authorDescription}
        </Author>
      )}
      {/* <Circle id="testimonials" value={data.score} clipId="testimonials" /> */}
    </Container>
  )
}

export default Testimonial
