import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq, { MaxWidth, ProseWidth } from 'styles/mq'

export const Wrapper = styled.section<{ dark?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'image' 'text';
  background: ${({ dark }) => (dark ? theme.color.background.gray : '#fff')};
  margin-top: 7rem;
  height: calc(100vh - 7rem);

  ${mq.medium} {
    min-height: unset;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'image text';
  }
`

export const Left = styled.div`
  overflow: hidden;
  pointer-events: none;
  grid-area: image;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

export const Right = styled.div`
  color: ${theme.color.text.dark};
  padding: 4rem 2.4rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-area: text;

  ${mq.mobileLarge} {
    padding: 6.4rem 4.8rem 8rem;
  }

  ${mq.medium} {
    padding: 3.4rem;
  }

  ${mq.large} {
    padding: 0 9.1rem 8.4rem;
  }

  ${mq.extraLarge} {
    padding: 0 15rem 30rem;
  }
`

export const Title = styled.h1`
  ${heading.xxl};
  color: ${theme.color.text.dark};
  grid-area: text;
  pointer-events: none;
  max-width: 60rem;

  ${mq.extraSmall} {
    font-size: 3.6rem;
  }

  ${mq.desktop} {
    ${heading.xl};
  }
`

export const Paragraph = styled.p`
  ${paragraph.normal};
  margin: 0 0 3rem;
  max-width: ${ProseWidth};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  a {
    text-align: center;
  }

  ${mq.medium} {
    a {
      max-width: 20rem;
    }
  }
`
