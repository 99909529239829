import styled from 'styled-components'
import { heading } from 'styles/typography'

export const Wrapper = styled.div`
  position: relative;
`

export const Background = styled.div<{
  value: number
  clip: string
  color: string
  background?: string
}>`
  clip-path: url(#${props => props.clip});
  background: ${props => props.background || 'rgba(151, 151, 151, 0.06)'};

  &,
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    ${props => `
      background: conic-gradient(
        ${props.color}00,
        ${props.color} ${props.value}%,
        ${props.color}00 ${props.value}%
      );
    `}
  }

  svg:not(.clip),
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  @supports (background: conic-gradient(white, black)) {
    img,
    svg:not(.clip) {
      display: none;
    }
  }
`

export const ClipSvg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
`

export const Value = styled.div`
  ${heading.xxl};
  display: block;
  position: relative;
  text-align: center;
  font-size: 0.34em !important;
  line-height: 1;
  padding: 0.3em 0.1em 0 0;
  margin: 0;
  z-index: 1;
`

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
`
