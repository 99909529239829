import Urls from 'common/urls'
import { ExternalLink } from 'components/links'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from 'hooks/use-media-query'
import React, { FC } from 'react'
import { ScreenSize } from 'styles/mq'
import { Section } from '../styled'
import { Container, Left, Paragraph, Right, Title } from './styled'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  breakthroughsHero: FluidImage
  mobileBreakthroughsHero: FluidImage
}

const query = graphql`
  query {
    breakthroughsHero: file(
      relativePath: { eq: "breakthroughs-hero-desktop.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 85, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileBreakthroughsHero: file(
      relativePath: { eq: "breakthroughs-hero-mobile.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const EnablingBreakthroughs: FC = () => {
  const { breakthroughsHero, mobileBreakthroughsHero } = useStaticQuery<
    QueryResult
  >(query)

  const isDesktop = useMediaQuery(`(min-width: ${ScreenSize.D}px)`)

  return (
    <Section>
      <Container>
        <Left>
          <Title>
            Enabling breakthroughs in men&apos;s health, one sample at a time
          </Title>
          <Paragraph>
            Fellow is building a biobank of semen samples so that the most
            potent and accessible analyte—seminal fluid—can be used to yield
            breakthroughs across medical disciplines.
          </Paragraph>
          <ExternalLink to={Urls.science} inverse>
            Our science
          </ExternalLink>
        </Left>
        <Right>
          <Img
            fluid={
              isDesktop
                ? breakthroughsHero.childImageSharp.fluid
                : mobileBreakthroughsHero.childImageSharp.fluid
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        </Right>
      </Container>
    </Section>
  )
}

export default EnablingBreakthroughs
