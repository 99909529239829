import styled from 'styled-components'
import mq from 'styles/mq'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'text'
    'image';
  max-width: 137.8rem;
  margin: 0 auto;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'text image';
  }

  ${mq.large} {
    grid-template-columns: 5fr 7fr;
  }
`

export const Left = styled.div`
  color: ${theme.color.text.dark};
  padding: 0 0 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-area: text;

  ${mq.medium} {
    padding: 0 2.4rem 4.8rem 0;
  }
`

export const Right = styled.div`
  overflow: hidden;
  pointer-events: none;
  grid-area: image;

  .gatsby-image-wrapper {
    height: 35rem;
    margin: 0 auto;

    ${mq.medium} {
      height: 100%;
    }
  }

  ${mq.desktop} {
    padding-right: 2.4rem;
  }
`

export const Title = styled.h1`
  ${heading.xl};
  color: ${theme.color.text.dark};
  grid-area: text;
  pointer-events: none;
`

export const Paragraph = styled.p`
  ${paragraph.normal};
  margin-bottom: 3.2rem;
`
