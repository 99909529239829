import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import mq, { MaxWidth } from 'styles/mq'
import { ButtonLink } from 'components/button'
import theme from 'styles/theme'

// Uses a unique section because the vertically translated component requires
// a different bottom padding
export const Section = styled.section<{ dark?: boolean }>`
  position: relative;
  background: ${({ dark }) =>
    dark ? theme.color.background.gray : theme.color.background.light};
  padding: 6rem 2.4rem;

  ${mq.medium} {
    padding: 8rem 2.4rem 0;
  }
`

export const InfoCard = styled.div`
  max-width: ${MaxWidth / 10}rem;
  border-radius: 2.4rem;
  background: ${theme.color.background.darkPurple};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1.4rem;
  margin: 0 auto 6rem;

  ${mq.medium} {
    padding: 10rem 14rem 15rem;
  }
`

export const CardEyebrow = styled.span`
  ${heading.eyebrow};
  color: ${theme.color.text.gold};
  text-align: center;
  margin-bottom: 2rem;
`

export const CardTitle = styled.h2`
  color: ${theme.color.text.light};
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.8rem;
  letter-spacing: -0.12rem;
  max-width: 82rem;

  ${mq.medium} {
    font-size: 4.4rem;
  }
`

export const CardParagraph = styled.p`
  color: ${theme.color.text.light};
  text-align: center;
  margin: 0;
  line-height: 1.4;
  max-width: 70ch;
`

export const Triad = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;
  gap: 6rem;

  ${mq.medium} {
    flex-direction: row;
    transform: translateY(-14rem);
    gap: unset;
  }
`

export const TriadItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2.4rem;

  .gatsby-image-wrapper {
    width: 30rem;
    margin: 0 -1rem 2rem;

    img {
      object-fit: cover;
    }

    ${mq.medium} {
      align-self: center;
      width: 20rem;
    }

    ${mq.desktop} {
      width: 30rem;
    }
  }

  ${mq.medium} {
    width: 33%;
    padding: 0 2rem;
    margin-bottom: 0;
  }

  ${mq.large} {
    padding: 0 4rem;
  }
`

export const ItemTitle = styled.div`
  ${heading.m};
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
`

export const ItemParagraph = styled.div`
  ${paragraph.small};
  margin-bottom: 2rem;

  ${mq.medium} {
    font-size: 1.8rem;
  }
`

export const CTAButton = styled(ButtonLink as any)`
  text-align: center;
  margin-top: auto;

  ${mq.medium} {
    margin-top: auto;
  }
`
