import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Wrap = styled.ol`
  padding: 0 1.5rem;
  margin-top: 8rem;

  ${mq.desktop} {
    padding: 0;
    margin-top: 0;
  }
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${paragraph.normal};
  border-top: 0.2rem solid ${theme.color.border.lighter};
  padding: 4rem 4rem 2rem 0;

  &:last-of-type {
    border-bottom: 0.2rem solid ${theme.color.border.lighter};
  }

  ${mq.medium} {
    flex-direction: row;
  }
`

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
`

export const Paragraph = styled.p`
  ${paragraph.small};

  ${mq.large} {
    max-width: 42rem;
  }
`
export const Title = styled.h6`
  ${heading.s};
  margin-bottom: 0;
`

export const IconWrap = styled.div`
  height: 8rem;
  min-height: 8rem;
  max-height: 8rem;
  width: 8rem;
  min-width: 8rem;
  max-width: 8rem;
  margin: 1rem 2rem 2rem 0;
  display: flex;
  flex: 1;
  align-items: flex-end;
  color: ${theme.color.text.light};
  background-color: ${theme.color.background.blue};
  border-radius: 50%;

  svg {
    height: 4rem;
    width: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    margin: auto;
  }
`
