import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq, { MaxWidth } from 'styles/mq'

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'image'
    'text';
  padding: 3rem 0 6rem;
  background: ${theme.color.background.light};

  ${mq.medium} {
    padding: 4rem 0 8rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'text image';
  }

  ${mq.large} {
    grid-template-columns: 5fr 7fr;
  }
`

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'text'
    'image';
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'text image';
  }

  ${mq.large} {
    grid-template-columns: 5fr 7fr;
  }
`

export const Left = styled.div`
  color: ${theme.color.text.dark};
  padding: 6rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-area: text;

  ${mq.medium} {
    padding: 0 6.2rem 0 0;
  }
`

export const Right = styled.div`
  overflow: hidden;
  pointer-events: none;
  grid-area: image;

  .gatsby-image-wrapper {
    height: 35rem;
    margin: 0 auto;

    ${mq.medium} {
      height: 100%;
    }
  }
`

export const Title = styled.h1`
  ${heading.xxl};
  color: ${theme.color.text.dark};
  grid-area: text;
  margin-top: -4rem;
  margin-block-end: 0.2em;
  z-index: 1;
  pointer-events: none;

  ${mq.extraSmall} {
    font-size: 3.6rem;
  }

  ${mq.medium} {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    align-items: left;
    justify-content: left;
    grid-area: unset;
  }

  ${mq.desktop} {
    font-size: 4.4rem;
    margin-bottom: 0.6rem;
  }
`

export const Paragraph = styled.p`
  ${paragraph.normal};
  margin: 0 0 3rem;
`
