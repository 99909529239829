import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const PersonOutlineCheckmark: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 52 52" fill="none">
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M41.134 29.981a6.843 6.843 0 1 0 4.332 7.414m-9.758-2.758 3.371 3.272 9.336-9.364M23.89 23.188h-3.157C15.36 23.188 11 27.583 11 33v5.226m10.742-15.04h3.156c3.109 0 5.878 1.472 7.66 3.76m-4.37-14.502a6.445 6.445 0 1 1-12.891 0 6.445 6.445 0 0 1 12.89 0Z"
    />
  </Svg>
)

export default PersonOutlineCheckmark
