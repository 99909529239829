import React from 'react'

const Yale = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-26 0 180 54">
    <path
      d="M41.59 11.34L28.857 32.786v14.426c1.466 3.471 5.17 3.934 8.72 4.088v2.006H12.654V51.3c3.55-.154 7.87-.694 9.028-3.008V33.403L8.719 11.494C6.482 7.791 4.321 6.558 0 6.25V4.243h21.682V6.25c-4.09.231-5.401 1.003-6.558 1.93L27.16 29.005 38.966 8.255c-1.234-.926-2.855-1.775-6.944-2.006V4.244H50.54v2.005c-4.166.155-6.79 1.542-8.95 5.091zM66.897 54c-4.166 0-6.173-2.854-6.327-4.86-2.624 2.777-6.018 4.86-9.26 4.86-4.166 0-7.716-2.546-7.716-6.942 0-2.16.54-4.398 1.621-6.172l15.355-5.554v-5.709c0-3.471-1.62-6.788-5.093-6.788-2.083 0-4.09 2.545-4.244 6.48L44.29 30.47v-.77c2.315-6.944 9.954-9.643 13.658-9.643 7.022 0 9.568 3.702 9.49 9.41l-.23 16.2c-.078 3.163 1.62 3.935 3.163 3.935 1.234 0 2.469-.772 3.935-2.237v2.237C72.608 51.609 69.6 54 66.898 54zM51.466 41.426c-.309 1.234-.54 2.314-.54 4.011 0 2.314 1.312 4.628 3.858 4.628 2.315 0 3.626-.77 5.71-2.39l.077-10.415c-2.006.925-6.944 3.086-9.105 4.166zM75 53.306V51.53c3.472-.23 4.475-1.002 5.093-2.314V9.257c0-2.623-.386-3.703-5.71-4.166V3.55L88.502 0l.463 1.312-2.237 2.777v45.128c.771 1.543 2.237 2.083 5.4 2.315v1.774H75zm35.648.617c-8.102 0-14.352-6.403-14.352-15.351 0-10.955 6.713-18.438 16.204-18.438 7.639 0 11.883 4.321 11.883 11.186v1.389l-20.834-.154c-.154.848-.232 2.237-.232 3.934 0 7.868 4.939 13.037 11.498 13.037 3.935 0 5.941-1.389 9.027-4.012L125 47.288c-4.398 4.012-8.025 6.635-14.352 6.635zm.695-31.397c-3.859 0-6.559 3.086-7.485 7.945l13.581-.154c0-4.088-2.547-7.79-6.096-7.79z"
      fill="currentColor"
    />
  </svg>
)

export default Yale