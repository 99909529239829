import React, { FC } from 'react'

const RAD = Math.PI / 180
const PI_2 = Math.PI / 2
const radius = 0.5
const cx = 0.5
const cy = 0.5

const Fallback: FC<{ value: number; color: string }> = ({ color, value }) => {
  const delta = value * 3.6
  const largeArc = delta > 180 ? 1 : 0

  const a1 = -PI_2
  const a2 = delta * RAD - PI_2

  const x1 = cx + radius * Math.cos(a2)
  const y1 = cy + radius * Math.sin(a2)

  const x2 = cx + radius * Math.cos(a1)
  const y2 = cy + radius * Math.sin(a1)

  return (
    <svg viewBox="0 0 1 1">
      <path
        fill={color}
        d={`M ${x1} ${y1} A ${radius} ${radius} 0 ${largeArc} 0 ${x2} ${y2} L ${cx} ${cy}z`}
      />
    </svg>
  )
}

export default Fallback
