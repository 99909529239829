import React from 'react'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Hero from './hero'
import Institutions from 'components/institutions'
import FellowSupports from './fellow-supports'
import TestimonialsCarousel from 'components/testimonials-carousel'
import ThreeKits from './three-kits'
import UCSF from 'components/icons/institutions/ucsf'
import USCKeck from 'components/icons/institutions/usc-keck'
import Stanford from 'components/icons/institutions/stanford'
import Yale from 'components/icons/institutions/yale'
import HackensackMeridian from 'components/icons/institutions/hackensack-meridian'
import ClinicalEfficiency from './clinical-efficiency'
import ReferencesFootnote from 'components/references-footnote'
import EnablingBreakthroughs from './enabling-breakthroughs'

const IndexPage = () => {
  const logos = [
    {
      href: 'https://urology.ucsf.edu/',
      ariaLabel: 'Visit the UCSF urology department website',
      element: <UCSF />,
    },
    {
      href: 'https://urology.keckmedicine.org/',
      ariaLabel: 'Visit the Keck Medicine urology department website',
      element: <USCKeck />,
    },
    {
      href:
        'https://www.stanfordchildrens.org/en/service/fertility-and-reproductive-health',
      ariaLabel:
        "Visit the Stanford Children's fertility and reproductive health website",
      element: <Stanford />,
    },
    {
      href:
        'https://www.hackensackumc.org/services/urology/our-services/mens-urology/',
      ariaLabel:
        'Visit the Hackensack University Medical Center urology department website',
      element: <HackensackMeridian />,
    },
    {
      href: 'https://www.yale.edu/',
      ariaLabel: 'Visit the Yale University website',
      element: <Yale />,
    },
  ]

  const references = [
    {
      symbol: '§',
      content:
        'Samplaski et al. Development and validation of a novel mail-in semen analysis system and the correlation between one hour and delayed semen analysis testing; Fertil Steril. 2021;115(4):922-929',
    },
    {
      symbol: '†',
      content:
        'Tolani et al. Mail-in semen analysis breaks down racial, educational, and income barriers to semen analysis completion. Proceedings from the Pacific Coast Reproductive Society (PCRS) Annual Meeting 2024. Indian Wells, CA.',
    },
    {
      symbol: '‡',
      content:
        'Gu et al. High compliance with Fellow semen analysis testing across the United States irrespective of clinical practice type. Proceedings from the American Urological Association Annual Meeting 2024. San Antonio, TX. ',
    },
    {
      symbol: '*',
      content: `
        Multiple publications report only 1 in 2 patients complete post-vasectomy semen
        analysis, including <a href="https://www.goldjournal.net/article/S0090-4295(19)31026-X/fulltext" target="_blank" rel="noopener noreferrer">[1]</a> Bradshaw et al., Urology, 2019,
        <a href="https://www.auajournals.org/doi/abs/10.1016/j.juro.2013.01.062" target="_blank" rel="noopener noreferrer"> [2] </a>
        Duplisea and Whelan, Journal of Urology, 2013 and
        <a href="https://www.scitechnol.com/peer-review/post-vasectomy-semen-analysis-factors-predicting-compliance-xgLE.php?article_id=9391" target="_blank" rel="noopener noreferrer"> [3] </a>
        Johnson (Siegert) et al., Andrology & Gynecology: Current Research, 2019.
      `,
      html: true,
    },
  ]

  return (
    <Layout headerPosition="fixed">
      <SEO
        title="Sperm Testing | Male Fertility | Semen Analysis"
        description="Test sperm privately from home and get a doctor-reviewed male fertility report containing detailed semen analysis results."
      />
      <Hero />
      <Institutions
        title="Trusted by leading urologists and reproductive specialists"
        logos={logos}
      />
      <ClinicalEfficiency />
      <FellowSupports />
      <ThreeKits />
      <TestimonialsCarousel
        eyebrow="Success Stories"
        header="Helping hundreds of providers move patient care forward"
        dark
        showDoctors
        showImages={false}
        showEmdash={false}
      />
      <EnablingBreakthroughs />
      <ReferencesFootnote references={references} />
    </Layout>
  )
}

export default IndexPage
