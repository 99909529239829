import React, { FC } from 'react'

import { getColorByScore } from 'styles/theme'
import Fallback from './fallback'
import {
  Wrapper,
  Background,
  Container,
  Value,
  ChildrenContainer,
} from './styled'
import ClipPath, { ThicknessType } from './clip-path'

const Circle: FC<{
  value: number
  className?: string
  id: string
  clipId?: string
  thickness?: ThicknessType
  background?: string
}> = ({
  children,
  value,
  id,
  clipId,
  thickness,
  className = '',
  background,
}) => {
  const color = getColorByScore(value)

  return (
    <Wrapper className={className}>
      <Container>
        <Background
          color={color}
          value={value}
          background={background}
          clip={`clip-${id}`}
        >
          {!clipId && <ClipPath id={id} thickness={thickness} />}
          <Fallback value={value} color={color} />
        </Background>

        {children ? (
          <ChildrenContainer>{children}</ChildrenContainer>
        ) : (
          <Value>{value}</Value>
        )}
      </Container>
    </Wrapper>
  )
}

export default Circle
