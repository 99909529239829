import { useState, useEffect } from 'react'

/**
 * Courtesy of https://usehooks-ts.com/react-hook/use-media-query
 * Read more about `window.matchMedia` here:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 *
 * You can use this hook to automatically detect when the media matches the passed
 * media query string. Example usage:
 *
 * const isDesktop = useMediaQuery("(min-width: 1024px)")
 * return isDesktop ? "The window width is >= 1024px" : "The window width is < 1024px"
 */
const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}

export default useMediaQuery
