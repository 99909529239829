import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import urls from 'common/urls'
import {
  Section,
  Triad,
  TriadItem,
  ItemTitle,
  ItemParagraph,
  CTAButton,
  InfoCard,
  CardEyebrow,
  CardTitle,
  CardParagraph,
} from './styled'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  kitImage: FluidImage
  kitPlusCryoImage: FluidImage
  vasectomyKitImage: FluidImage
}

const query = graphql`
  query {
    kitImage: file(relativePath: { eq: "kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitPlusCryoImage: file(relativePath: { eq: "kit-plus-cryo-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vasectomyKitImage: file(
      relativePath: { eq: "vasectomy-kit-top-view.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ThreeKits = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <InfoCard>
        <CardEyebrow>Our Tests</CardEyebrow>
        <CardTitle>
          Pioneering a better patient experience to maximize compliance
        </CardTitle>
        <CardParagraph>
          We dramatically improve the testing experience for patients and clinic
          staff members, achieving compliance rates of up to 97%<sup>‡</sup>{' '}
          (compared to an established average of about 50% for in-office
          testing*) while providing results that are clinically validated to be
          as accurate as those from a fresh semen sample analyzed within 1 hour.
        </CardParagraph>
      </InfoCard>
      <Triad>
        <TriadItem id="vasectomy-test-triad-item">
          <Img
            fluid={{
              ...data.vasectomyKitImage.childImageSharp.fluid,
            }}
            alt="An image of the Fellow Vasectomy Test"
          />
          <div>
            <ItemTitle>
              Vasectomy
              <br />
              Test
            </ItemTitle>
            <ItemParagraph>
              The most convenient way for patients to test the success of their
              vasectomy.
            </ItemParagraph>
          </div>
          <CTAButton to={urls.vasectomyKit}>About this kit</CTAButton>
        </TriadItem>
        <TriadItem>
          <Img
            fluid={{
              ...data.kitImage.childImageSharp.fluid,
            }}
            alt="An image of the Fellow Semen Analysis kit"
          />
          <div>
            <ItemTitle>
              Semen
              <br />
              Analysis
            </ItemTitle>
            <ItemParagraph>
              Easy,<sup>†</sup> clinically validated semen analysis with results
              in less than 2 business days from when we receive a sample.
            </ItemParagraph>
          </div>
          <CTAButton to={urls.kit}>About this kit</CTAButton>
        </TriadItem>
        <TriadItem>
          <Img
            fluid={{
              ...data.kitPlusCryoImage.childImageSharp.fluid,
            }}
            alt="An image of the Fellow Analysis + Cryopreservation"
          />
          <div>
            <ItemTitle>Semen Analysis + Cryopreservation</ItemTitle>
            <ItemParagraph>
              Following a full semen analysis, secure your fertility future with
              our reliable cryopreservation services.
            </ItemParagraph>
          </div>
          <CTAButton to={urls.kitPlusCryo}>About this kit</CTAButton>
        </TriadItem>
      </Triad>
    </Section>
  )
}

export default ThreeKits
