import styled from 'styled-components'
import Img from 'gatsby-image'
import theme from 'styles/theme'
import { heading } from 'styles/typography'
import mq, { ScreenSize } from 'styles/mq'
import Button from 'components/button'

export const Section = styled.section<{ dark?: boolean }>`
  text-align: center;
  padding: 6rem 0;

  ${mq.medium} {
    padding: 8rem 0;

    .slick-slider button {
      margin-bottom: 0;
    }
  }

  .slick-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  background: ${({ dark }) => (dark ? theme.color.background.bone : '#fff')};
`

export const SectionEyebrow = styled.p`
  text-align: left;
  ${heading.eyebrow};
  color: ${theme.color.text.brown};
  margin-bottom: 2rem;

  ${mq.medium} {
    text-align: center;
  }
`

export const SectionTitle = styled.h2`
  ${heading.xl};
  max-width: ${ScreenSize.MD / 10}rem;
  margin: auto;
  text-align: left;

  ${mq.medium} {
    padding: 0 2rem;
    text-align: center;
  }
`

export const Counter = styled.span`
  visibility: hidden;

  ${mq.medium} {
    ${heading.xxs};
    visibility: visible;
    padding: 1.2rem 2.2rem;
    color: ${theme.color.text.brown};
  }
`

export const CTAButton = styled(Button as any)`
  margin: 6.4rem auto 0;
  display: block;
`

export const TestimonialsHero = styled(Img as any)`
  margin: 8rem auto;
  display: none;

  ${mq.medium} {
    display: block;
  }
`

export const MobileTestimonialsHero = styled(Img as any)`
  margin: 8rem auto;

  ${mq.medium} {
    display: none;
  }
`
