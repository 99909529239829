import styled from 'styled-components'
import { heading } from 'styles/typography'
import mq from 'styles/mq'

export const SectionTitle = styled.h2`
  ${heading.m};
  line-height: 2.8rem;

  ${mq.mobileLarge} {
    ${heading.xl};
    margin-bottom: 6rem !important;
    text-align: center;
  }
`

export const Triad = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 120rem;
  margin: 0 auto;

  ${mq.mobileLarge} {
    align-items: stretch;
  }

  ${mq.medium} {
    flex-direction: row;
  }
`

export const TriadItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4rem;
  text-align: left;

  ${mq.mobileLarge} {
    align-items: center;
    text-align: center;
  }

  ${mq.medium} {
    width: 33%;
    padding: 0 2rem;
    margin-bottom: 0;
  }

  ${mq.large} {
    padding: 0 4rem;
  }
`

export const ItemTitle = styled.div`
  ${heading.xxl};
  font-size: 8rem;
`

export const ItemParagraph = styled.div`
  font-size: 1.4rem;
  font-weight: bold;

  ${mq.mobileLarge} {
    font-size: 1.6rem;
  }

  sup {
    font-size: 0.9rem;
  }
`
