import styled, { css } from 'styled-components'

import { heading, paragraph } from 'styles/typography'
import CircleBase from 'components/circle'
import mq from 'styles/mq'

export const Container = styled.div`
  width: 100%;
  text-align: center;
`

export const Quote = styled.blockquote`
  ${paragraph.small};
  margin: 0 auto 3rem;
  max-width: 71rem;
  text-align: left;

  ${mq.medium} {
    margin-top: 2.2rem;
    text-align: center;
    font-size: 2.2rem;
  }

  &:not(:empty):before {
    content: '“';
  }

  &:not(:empty):after {
    content: '”';
  }
`

interface AuthorProps {
  showEmdash?: boolean
}

export const Author = styled.span<AuthorProps>`
  ${paragraph.extraSmall};
  font-weight: bold;
  display: block;
  text-align: left;

  ${mq.medium} {
    text-align: center;
  }

  ${({ showEmdash }) =>
    showEmdash &&
    css`
      &:not(:empty):before {
        content: '—';
      }
    `}
`

export const Description = styled.span`
  ${paragraph.extraSmall};
  display: block;
  text-align: left;

  ${mq.medium} {
    text-align: center;
  }
`

export const Circle = styled(CircleBase)`
  display: flex;
  justify-content: center;
  font-size: 5rem;
  margin-top: 3rem;
`
