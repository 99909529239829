import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import Urls from 'common/urls'
import { Wrapper, Left, Right, Title, Paragraph, ButtonWrapper } from './styled'
import { ButtonLink } from 'components/button'

type QueryResult = {
  heroDesktop: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    heroDesktop: file(relativePath: { eq: "hero-collage-desktop-v3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Hero: FC = () => {
  const { heroDesktop } = useStaticQuery<QueryResult>(query)
  return (
    <Wrapper>
      <Left>
        <Img
          fluid={heroDesktop.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover', objectPosition: 'center right' }}
          loading="eager"
          fadeIn={false}
        />
      </Left>
      <Right>
        <Title>Unlocking clinical insights with semen</Title>
        <Paragraph>
          <span translate="no">Fellow </span>provides peer-reviewed, mail-in semen analysis for clinics
          looking for improved compliance and a patient-friendly solution to
          fertility testing.
        </Paragraph>
        <ButtonWrapper>
          <ButtonLink to={Urls.clinicians}>For providers</ButtonLink>
          <ButtonLink to={Urls.shop}>For patients</ButtonLink>
        </ButtonWrapper>
      </Right>
    </Wrapper>
  )
}

export default Hero
